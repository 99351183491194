<template>
  <AddAuditSection
    :caseData="caseValue"
    :display="viewAddSection"
    @closeAudit="closeAddSection"
    :index="indexSection"
  ></AddAuditSection>
  <div class="text-right pb-3">
    <Button v-if="!isMobile" :label="$t('global.export')" @click="exportCSV"></Button>
  </div>
  <DataTable
    :paginator="true"
    :rows="10"
    :value="caseValue.auditPlan.auditSections"
    dataKey="id"
    ref="tableAuditSections"
    showGridlines
    class="p-datatable-roles"
    :class="{
      'invalidTable': showError
    }"
    :loading="loading"
    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
    :rowsPerPageOptions="[5, 10, 25, 50]"
    :currentPageReportTemplate="$tableString"
    removableSort
    sortField="auditSectionDate"
    :sortOrder="-1"
  >
    <Column
      field="auditSectionDate"
      style="width: 15%"
      :header="$t('calendar.date')"
      :sortable="true"
    >
      <template #body="{ data }">
        {{  $dayjs(data.auditSectionDate,'DD/MM/YYYY').format('DD/MM/YYYY') }}
      </template>
    </Column>

    <Column
      field="auditSectionFrom"
      style="width: 10%"
      :header="$t('calendar.fromTo')"
      :sortable="true"
    >
      <template #body="{ data }">
        {{ data.auditSectionFrom }} - {{ data.auditSectionTo }}
      </template>
    </Column>

    <Column
      field="auditPart"
      style="width: 20%"
      :header="$t('calendar.auditPart')"
      :sortable="true"
    >
      <template #body="{ data }">
        {{ data.auditPart }}
      </template>
    </Column>

    <Column
      field="companyRep"
      :header="$t('calendar.companyRep')"
      :sortable="true"
    >
      <template #body="{ data }">
        {{ data.companyRep }}
      </template>
    </Column>

    <Column field="auditor" :header="$t('calendar.auditor')" :sortable="true">
      <template #body="{ data }">
        <span class="text-right"
          >{{ auditorData(data.auditor).firstName }}
          {{ auditorData(data.auditor).lastName }}</span
        >
      </template>
    </Column>

    <Column field="comments" :header="$t('calendar.comments')">
      <template #body="{ data }">
        {{ data.comments }}
      </template>
    </Column>
    <Column field="actions" :header="$t('global.actions')" v-if="caseValue.status == CaseStatuses.EVENT_ACCEPTED_BY_CLIENT.name || caseValue.status == CaseStatuses.SCHEDULE_REJECTED_BY_CLIENT.name">
      <template #body="{ index }">
        <div class="flex justify-content-evenly">
          <Button
            class="p-button-text mdi mdi-table-edit mdi-24px"
            @click="editSection(index)"
          />
          <Button
            class="p-button-text mdi mdi-close-circle mdi-24px"
            @click="deleteSection(index)"
          />
        </div>
      </template>
    </Column>
  </DataTable>
</template>

<script>
import AddAuditSection from "./AddAuditSection";
import { mapState } from "vuex";
export default {
  name: "Audit sections table",
  components: {
    AddAuditSection,
  },
  props: ["caseData", "loadingValue", "showError"],
  data() {
    return {
      loading: false,
      caseValue: this.caseData,
      viewAddSection: false,
      indexSection: null,
    };
  },
  watch: {
    loadingValue(val) {
      this.loading = val;
    },
    caseData() {
      this.caseValue = this.caseData;
    },
  },
  computed: {
    ...mapState(["CaseStatuses","isMobile"]),
  },
  methods: {
    closeAddSection() {
      this.indexSection = null;
      this.viewAddSection = false;
    },
    exportCSV() {
      this.$refs.tableAuditSections.exportCSV();
      this.$action("export-audit-sections");
    },
    auditorData(id) {
      return this.caseValue.auditors.find((el) => el.id == id);
    },
    editSection(index) {
      this.indexSection = index;
      this.viewAddSection = true;
    },
   async deleteSection(index) {
      this.caseValue.auditPlan.auditSections.splice(index, 1);
      await this.$store.dispatch("auditTask", {
        sections: this.caseValue.auditPlan.auditSections,
        id: this.caseValue.auditPlan.id,
        version: this.caseValue.auditPlan.version,
        caseId: this.caseValue.id,
      }).then(() => {
        this.$action("audit-sections-delete", {caseId: this.caseValue.id});
      })
    },
  },
};
</script>


<style lang="scss" scoped>

.invalidTable {
  border: 2px solid #ef9a9a;
}

</style>
