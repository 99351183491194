<template>
  <div>
    <Dialog
      :header="
        index == null
          ? $t('calendar.createAuditSection')
          : $t('calendar.editAuditSection')
      "
      v-model:visible="viewDialog"
      :draggable="false"
      :modal="true"
      :style="{ width: '40vw' }"
      class="manualTabs"
      appendTo="self"
      style="max-height: 100% !important"
    >
      <div class="grid vertical-container align-items-center">
        <div class="col-12 md:col-3 pb-0 md:pb-3">
          <label for="date">{{ $t("calendar.date") }}*:</label>
        </div>
        <div class="field col-12 md:col-6 pb-3 pt-0 md:pt-3">
          <Calendar
            id="date"
            :class="{
              'p-invalid':
                v$.auditSection.auditSectionDate.$invalid && submitted,
            }"
            aria-describedby="date-error"
            v-model="v$.auditSection.auditSectionDate.$model"
            :placeholder="$t('calendar.date') + ' *'"
            autocomplete="off"
            :showIcon="true"
            dateFormat="dd/mm/yy"
            :selectOtherMonths="true"
            :minDate="$dayjs(caseValue.auditDate, 'DD/MM/YYYY').toDate()"
            :maxDate="$dayjs(caseValue.auditDateEnd, 'DD/MM/YYYY').toDate()"
          />
        </div>
        <ul
          v-if="v$.auditSection.auditSectionDate.$error && submitted"
          class="p-error text-xs col-12 md:col-9 col-offset-0 md:col-offset-3 pt-0 pl-5 mt-0"
        >
          <li
            v-for="(error, index) of v$.auditSection.auditSectionDate.$errors"
            :key="index"
          >
            {{ error.$message.replace("[value]", $t("calendar.date")) }}
          </li>
        </ul>
      </div>

      <div class="grid vertical-container align-items-center">
        <div class="col-12 md:col-3 pb-0 md:pb-3">
          <label for="timeFrom">{{ $t("calendar.timeFrom") }}*:</label>
        </div>
        <div tabindex="2" class="field col-12 md:col-6 pb-3 pt-0 md:pt-3">
          <Calendar
            id="timeFrom"
            :timeOnly="true"
            hourFormat="24"
            :class="{
              'p-invalid':
                v$.auditSection.auditSectionFrom.$invalid && submitted,
            }"
            aria-describedby="timeFrom-error"
            v-model="v$.auditSection.auditSectionFrom.$model"
            :placeholder="$t('calendar.timeFrom') + ' *'"
            autocomplete="off"
          />
        </div>
        <ul
          v-if="v$.auditSection.auditSectionFrom.$error && submitted"
          class="p-error text-xs col-12 md:col-9 col-offset-0 md:col-offset-3 pt-0 pl-5 mt-0"
        >
          <li
            v-for="(error, index) of v$.auditSection.auditSectionFrom.$errors"
            :key="index"
          >
            {{ error.$message.replace("[value]", $t("calendar.timeFrom")) }}
          </li>
        </ul>
      </div>

      <div class="grid vertical-container align-items-center">
        <div class="col-12 md:col-3 pb-0 md:pb-3">
          <label for="timeTo">{{ $t("calendar.timeTo") }}*:</label>
        </div>
        <div tabindex="3" class="field col-12 md:col-6 pb-3 pt-0 md:pt-3">
          <Calendar
            id="timeTo"
            :timeOnly="true"
            hourFormat="24"
            :class="{
              'p-invalid': v$.auditSection.auditSectionTo.$invalid && submitted,
            }"
            aria-describedby="timeTo-error"
            v-model="v$.auditSection.auditSectionTo.$model"
            :placeholder="$t('calendar.timeTo') + ' *'"
            autocomplete="off"
          />
          <!-- <small
            v-else-if="
              (v$.auditSection.auditSectionTo.$required && submitted) ||
              v$.auditSection.auditSectionTo.$pending.$response
            "
            class="p-error"
            >{{
              v$.auditSection.auditSectionTo.required.$message.replace(
                "[value]",
                $t("calendar.timeTo")
              )
            }}</small
          > -->
        </div>
        <ul
          v-if="v$.auditSection.auditSectionTo.$error && submitted"
          class="p-error text-xs col-12 md:col-9 col-offset-0 md:col-offset-3 pt-0 pl-5 mt-0"
        >
          <li
            v-for="(error, index) of v$.auditSection.auditSectionTo.$errors"
            :key="index"
          >
            {{ error.$message.replace("[value]", $t("calendar.timeTo")) }}
          </li>
        </ul>
      </div>

      <div class="grid vertical-container align-items-center">
        <div class="col-12 md:col-3 pb-0 md:pb-3">
          <label for="auditPart">{{ $t("calendar.auditPart") }}*:</label>
        </div>
        <div tabindex="4" class="field col-12 md:col-6 pb-3 pt-0 md:pt-3">
          <InputText
            id="auditPart"
            :class="{
              'p-invalid': v$.auditSection.auditPart.$invalid && submitted,
            }"
            aria-describedby="auditPart-error"
            v-model="v$.auditSection.auditPart.$model"
            :placeholder="$t('calendar.auditPart') + ' *'"
            :showIcon="true"
          />
        </div>
        <ul
          v-if="v$.auditSection.auditPart.$error && submitted"
          class="p-error text-xs col-12 md:col-9 col-offset-0 md:col-offset-3 pt-0 pl-5 mt-0"
        >
          <li
            v-for="(error, index) of v$.auditSection.auditPart.$errors"
            :key="index"
          >
            {{ error.$message.replace("[value]", $t("calendar.auditPart")) }}
          </li>
        </ul>
      </div>

      <div class="grid vertical-container align-items-center">
        <div class="col-12 md:col-3 pb-0 md:pb-3">
          <label for="companyRep">{{ $t("calendar.companyRep") }}:</label>
        </div>
        <div tabindex="5" class="field col-12 md:col-6 pb-3 pt-0 md:pt-3">
          <InputText
            id="companyRep"
            disabled
            aria-describedby="companyRep-error"
            v-model="auditSection.companyRep"
            :placeholder="$t('calendar.companyRep')"
            :showIcon="true"
          />
        </div>
      </div>

      <div class="grid vertical-container align-items-center">
        <div class="col-12 md:col-3 pb-0 md:pb-3">
          <label for="auditor">{{ $t("calendar.auditor") }}*:</label>
        </div>
        <div class="field col-12 md:col-6 pb-3 pt-0 md:pt-3">
          <!-- <InputText
            id="auditor"
            aria-describedby="auditor-error"
            v-model="userInfo.name"
            :readonly="true"
            :placeholder="$t('calendar.auditor')"
            :showIcon="true"
          /> -->
          <Dropdown
            id="auditor"
            :class="{
              'p-invalid': v$.auditSection.auditor.$invalid && submitted,
            }"
            class="w-full"
            v-model="v$.auditSection.auditor.$model"
            :placeholder="$t('calendar.auditor') + ' *'"
            :options="caseValue.auditors"
          >
            <template #option="slotProps">
              {{ slotProps.option.firstName }} {{ slotProps.option.lastName }}
            </template>
            <template #value="slotProps">
              <div
                v-if="
                  typeof slotProps.value == 'string' &&
                  slotProps.value.length > 0
                "
              >
                {{
                  caseValue.auditors.find((el) => el.id == slotProps.value)
                    .firstName
                }}
                {{
                  caseValue.auditors.find((el) => el.id == slotProps.value)
                    .lastName
                }}
              </div>
              <div v-else-if="slotProps.value">
                {{ slotProps.value.firstName }} {{ slotProps.value.lastName }}
              </div>
              <div v-else>
                {{ $t("calendar.auditor") + " *" }}
              </div>
            </template>
          </Dropdown>
        </div>
        <ul
          v-if="v$.auditSection.auditor.$error && submitted"
          class="p-error text-xs col-12 md:col-9 col-offset-0 md:col-offset-3 pt-0 pl-5 mt-0"
        >
          <li
            v-for="(error, index) of v$.auditSection.auditor.$errors"
            :key="index"
          >
            {{ error.$message.replace("[value]", $t("calendar.auditor")) }}
          </li>
        </ul>
      </div>

      <div class="grid vertical-container align-items-center">
        <div class="col-12 md:col-3 pb-0 md:pb-3">
          <label for="comments">{{ $t("calendar.comments") }}:</label>
        </div>
        <div tabindex="7" class="field col-12 md:col-6 pb-3 pt-0 md:pt-3">
          <InputText
            id="comments"
            v-model="auditSection.comments"
            :placeholder="$t('calendar.comments')"
            :showIcon="true"
          />
        </div>
      </div>
      <div class="grid">
        <div class="col">
          <span>* {{ $t("global.mandatoryFields") }}</span>
        </div>
      </div>

      <template #footer>
        <Button
          :label="index == null ? $t('global.create') : $t('global.save')"
          class="p-button-success"
          @click="create"
          autofocus
          :loading="loadingButton"
          iconPos="right"
        />
        <Button
          :label="$t('global.close')"
          @click="close"
          class="p-button-danger"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { helpers } from "@vuelidate/validators";
import { required } from "@/utilities/i18n-validators";
import { mapGetters } from "vuex";

export default {
  name: "Add audit section",
  props: ["caseData", "display", "index"],
  emits: ["closeAudit", "updateData"],
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      viewDialog: false,
      loadingButton: false,
      submitted: false,
      auditSection: {
        auditSectionDate: "",
        auditSectionFrom: "",
        auditSectionTo: "",
        auditPart: "",
        companyRep: "",
        auditor: "",
        comments: "",
      },
    };
  },
  validations() {
    return {
      auditSection: {
        auditSectionDate: {
          required,
        },
        auditSectionFrom: {
          required,
        },
        auditSectionTo: {
          required,
          checkTime: helpers.withMessage(
            this.$t("calendar.timeError"),
            this.checkTime(this.auditSection.auditSectionFrom)
          ),
        },
        auditPart: {
          required,
        },
        auditor: {
          required,
        },
      },
    };
  },
  watch: {
    display(val) {
      this.viewDialog = val;
      // console.log(this.caseValue);
      this.auditSection.companyRep = this.caseValue.companyRepresentative;
      if (this.caseValue.auditors.length == 1) {
        this.auditSection.auditor = this.caseValue.auditors[0];
      }
    },
    viewDialog(val) {
      if (val == false) {
        this.close();
      } else {
        // this.$nextTick(() => {
        //   document
        //     .querySelector(".manualTabs")
        //     .addEventListener("keydown", (e) => {
        //       if (e.code == "Tab") {
        //         e.stopImmediatePropagation();
        //         let index = parseInt(e.target.getAttribute("tabindex"));
        //         let nextValue = document.querySelector(
        //           `[tabindex="${index + 1}"]`
        //         );
        //         if (!nextValue) {
        //           nextValue = document.querySelector(`[tabindex="1"]`);
        //         }
        //         nextValue.click();
        //       }
        //     });
        // });

        if (this.index != null) {
          this.auditSection = {
            ...this.caseData.auditPlan.auditSections[this.index],
          };
          this.auditSection.auditSectionDate = this.$dayjs(
            this.auditSection.auditSectionDate,
            "DD/MM/YYYY"
          ).toDate();
          let tempParamFrom = this.auditSection.auditSectionFrom.split(":");
          let tempValueTo = this.auditSection.auditSectionTo.split(":");
          this.auditSection.auditSectionFrom = this.$dayjs()
            .set("hour", tempParamFrom[0])
            .set("minute", tempParamFrom[1])
            .toDate();

          this.auditSection.auditSectionTo = this.$dayjs()
            .set("hour", tempValueTo[0])
            .set("minute", tempValueTo[1])
            .toDate();
        }
        this.submitted = false;
      }
    },
  },
  computed: {
    ...mapGetters(["userInfo"]),
    caseValue() {
      return { ...this.caseData };
    },
  },
  methods: {
    close() {
      this.$emit("closeAudit");
      this.clearSection();
    },
    clearSection() {
      this.auditSection = {
        auditSectionDate: "",
        auditSectionFrom: "",
        auditSectionTo: "",
        auditPart: "",
        companyRep: this.caseValue.companyRepresentative,
        auditor:
          this.caseValue.auditors.length == 1
            ? (this.auditSection.auditor = this.caseValue.auditors[0])
            : "",
        comments: "",
      };
    },
    checkTime(paramFrom) {
      return helpers.withParams(
        { type: "checkTime", value: paramFrom },
        (valueTo) => {
          console.log(valueTo);
          console.log(paramFrom);
          console.log(this.$dayjs(valueTo).diff(this.$dayjs(paramFrom), "m"));
          return this.$dayjs(valueTo).diff(this.$dayjs(paramFrom), "m") > 0;
          // return (
          //   this.$dayjs(
          //     this.$dayjs()
          //       .set("hour", tempValueTo[0])
          //       .set("minute", tempValueTo[1])
          //   ).diff(
          //     this.$dayjs(
          //       this.$dayjs()
          //         .set("hour", tempParamFrom[0])
          //         .set("minute", tempParamFrom[1])
          //     ),
          //     "m"
          //   ) > 0
          // );
        }
      );
    },

    async create() {
      this.submitted = true;
      const result = await this.v$.$validate();
      if (result) {
        this.submitted = false;
        this.loadingButton = true;
        this.auditSection.auditSectionDate = this.$dayjs(
          this.auditSection.auditSectionDate
        ).format("DD/MM/YYYY");
        this.auditSection.auditSectionFrom = this.$dayjs(
          this.auditSection.auditSectionFrom
        ).format("HH:mm");
        this.auditSection.auditSectionTo = this.$dayjs(
          this.auditSection.auditSectionTo
        ).format("HH:mm");
        if (typeof this.auditSection.auditor == "object") {
          this.auditSection.auditor = this.auditSection.auditor.id;
        }
        let tempSectionsForSave = [...this.caseValue.auditPlan.auditSections];
        try {
          if (this.index != null) {
            tempSectionsForSave[this.index] = { ...this.auditSection };
          } else {
            tempSectionsForSave.push({ ...this.auditSection });
          }
          await this.$store
            .dispatch("auditTask", {
              sections: tempSectionsForSave,
              id: this.caseValue.auditPlan.id,
              version: this.caseValue.auditPlan.version,
              caseId: this.caseValue.id,
            })
            .then(() => {
              if (this.index != null) {
                this.$action("audit-sections-edit", {
                  caseId: this.caseValue.id,
                });
              } else {
                this.$action("audit-sections-create", {
                  caseId: this.caseValue.id,
                });
              }
            });
          if (this.index == null) {
            this.clearSection();
          }
          this.$notification(
            this.$t("calendar.createAuditSection"),
            this.index == null
              ? this.$t("calendar.auditSectionCreated")
              : this.$t("calendar.auditSectionUpdated")
          );
          this.$emit("updateData");
          this.loadingButton = false;
          if (this.index != null) {
            this.close();
          }
        } catch {
          let tempParamFrom = this.auditSection.auditSectionFrom.split(":");
          let tempValueTo = this.auditSection.auditSectionTo.split(":");
          this.auditSection.auditSectionFrom = this.$dayjs()
            .set("hour", tempParamFrom[0])
            .set("minute", tempParamFrom[1])
            .toDate();

          this.auditSection.auditSectionTo = this.$dayjs()
            .set("hour", tempValueTo[0])
            .set("minute", tempValueTo[1])
            .toDate();

          this.loadingButton = false;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.p-inputtext,
.p-multiselect,
.p-calendar {
  margin-right: 0.5rem;
  width: 100%;
}

.field {
  margin-bottom: 0;
}
</style>
